import { getLanguage } from '@/common/utils/strings/path.utils';
import { GtmLandingDashboard } from '@/core/domain/gtm/models/gtm-landig-dashboard.models';
import { SetDataLayerParamsUseCase } from '@/core/domain/gtm/usecases/setDataLayer.usecase';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'vg-card-giftcard-status',
    templateUrl: './card-giftcard-status.component.html',
    styleUrls: ['./card-giftcard-status.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CardGiftcardStatusComponent {
    constructor(
        private readonly gtmDataLayer: SetDataLayerParamsUseCase,
        private translate: TranslateService,
    ) {}

    @Input()
    cardsReceived: any[] = [
        {
            isType: 'gifts',
            id: 'VBUH67VWWSSLYC',
            date: '2024-02-26T17:30:04.832Z',
            expirationDate: '2025-02-26T17:30:04.832Z',
            gifter: 'Johanna',
            amount: 1000,
            status: 'review',
            thankYouMessageSent: null,
            recipient: 'pruebas',
            recipientEmail: 'testvivafan@gmail.com',
            design: {
                id: '61d8e684e3c8f72f403997e4',
                url: 'https://content.vivaaerobus.com/Upload-VB/vivagift/giftcards_01_site.png',
            },
            message: '¡Felicidades!',
        },
        {
            isType: 'orders',
            id: 'VBUH67VWWSSLYC',
            creationDate: '2021-04-15T17:30:04.832Z',
            deliveryDate: '2021-04-20T17:30:04.832Z',
            recipient: 'carlos.jimenez@mail.com',
            recipientEmail: 'testvivafan@gmail.com',
            amount: 1000,
            status: 'sent',
            thankYouMessageSent: null,
            design: {
                id: '61d8e684e3c8f72f403997e4',
                url: 'https://content.vivaaerobus.com/Upload-VB/vivagift/giftcards_01_site.png',
            },
            message: '¡Felicidades!',
            purchaseInfo: [
                {
                    invoiceId: 'uh67vwwtud7v',
                    name: 'Jose Rosas',
                    address: 'av jardin, cdmx, cdmx, MX, 02970',
                    cardType: 'MasterCard',
                    cardLastFour: '3575',
                    customizeLink:
                        'https://www.vivaaerobus.com/es-mx/vivagift/personalizar?pid=uh67vwwtud7v&gcvc=VB597823',
                },
            ],
        },
        {
            isType: 'compensations',
            id: 'SR5G4CEWZCDFMJ6',
            status: 'review',
            type: 'sgc',
            amount: 1000,
            gifter: 'Viva Aerobus',
            recipient: 'Salvador Hernandez',
            pnr: 'VBR907',
            operation: 'refund',
            activationDate: '2022-04-15T16:54:32.948Z',
            expirationDate: '9999-12-31T00:00:00.000Z',
        },
        {
            isType: 'compensations',
            id: 'SR9KSO22BDSUNWG',
            status: 'redeemed',
            type: 'mgc',
            amount: 1000,
            gifter: 'Viva Aerobus',
            recipient: 'Customer Programs',
            pnr: 'ABC126',
            operation: 'compensation',
            activationDate: '2024-02-08T01:31:56.727Z',
            expirationDate: '2025-02-08T01:26:31.686Z',
        },
        {
            id: 'SR5G4CEWZCDFMJ6',
            status: 'redeemed',
            type: 'sgc',
            amount: 586,
            gifter: 'Viva Aerobus',
            recipient: 'Salvador Hernandez',
            pnr: 'VBR968',
            operation: 'refund',
            activationDate: '2024-02-09T16:54:32.948Z',
            expirationDate: '9999-12-31T00:00:00.000Z',
        },
        {
            id: 'SR2EXV2V83F8DWG',
            status: 'redeemed',
            type: 'sgc',
            amount: 816,
            gifter: 'Viva Aerobus',
            recipient: 'Salvador Hernandez',
            pnr: 'VBR907',
            operation: 'refund',
            activationDate: '2024-02-09T16:23:35.359Z',
            expirationDate: '9999-12-31T00:00:00.000Z',
        },
    ];
    detailShowed = false;
    typeTitle: any = {
        mgc: 'venta o cambio de vuelo',
        sgc: 'cancelación o cambio de vuelo',
    };

    @Input() urlDetail: any;
    @Input() typeDetail = '';

    public status: any = {
        received: {
            text: this.translate.instant('vg-card-giftcard-status-received'),
            class: 'success',
        },
        redeemed: {
            text: this.translate.instant('vg-card-giftcard-status-redeemed'),
            class: 'info',
        },
        expired: {
            text: this.translate.instant('vg-card-giftcard-status-expired'),
            class: 'disabled',
        },
        review: {
            text: this.translate.instant('vg-card-giftcard-status-review'),
            class: 'warning',
        },
        canceled: {
            text: this.translate.instant('vg-card-giftcard-status-canceled'),
            class: 'tertiary',
        },
        created: {
            text: this.translate.instant('vg-card-giftcard-status-created'),
            class: 'success',
        },
        purchased: {
            text: this.translate.instant('vg-card-giftcard-status-purchased'),
            class: 'success',
        },
        sent: {
            text: this.translate.instant('vg-card-giftcard-status-sent'),
            class: 'light',
        },
        scheduled: {
            text: this.translate.instant('vg-card-giftcard-status-scheduled'),
            class: 'light',
        },
        compensation: {
            text: this.translate.instant(
                'vg-card-giftcard-status-compensation',
            ),
            class: 'light',
        },
        refund: {
            text: this.translate.instant('vg-card-giftcard-status-refund'),
            class: 'light',
        },
        cancellation: {
            text: this.translate.instant('vg-card-giftcard-status-cancellation'),
            class: 'light',
        },
        default: {
            text: this.translate.instant('vg-card-giftcard-status-default'),
            class: 'light',
        },
    };

    statusText: any = {
        compensation: this.translate.instant(
            'vg-card-giftcard-operation-type-compensation',
        ),
        refund: this.translate.instant(
            'vg-card-giftcard-operation-type-refund',
        ),
        default: this.translate.instant(
            'vg-card-giftcard-operation-type-default',
        ),
    };

    DatesCard = {
        EXPIRATION_DATE_MP: '9999-12-31T00:00:00.000Z',
    };

    imageArrowLeft = '/vg/dashboard/arrow-left.svg';
    imageArrowRight = '/vg/dashboard/arrow-right.svg';

    @Input()
    pagination = true;
    @Input()
    page = 1;
    @Input()
    numberCardsToShow = 3;

    isValidDate(date: any): boolean {
        return date instanceof Date && !isNaN(date.getTime());
    }

    getEndDate(card: any): any {
        const { expirationDate } = card;
        if (!expirationDate) {
            return new Date(
                new Date(card.date).setFullYear(
                    new Date(card.date).getFullYear() + 1,
                ),
            );
        } else {
            return expirationDate === this.DatesCard.EXPIRATION_DATE_MP
                ? this.translate.instant(
                      'vg_dashboard_card_giftcard_status_badge_without-expiration',
                  )
                : new Date(expirationDate);
        }
    }

    getEndDateOrders(date: string): string {
        return new Date(
            new Date(date).setFullYear(new Date(date).getFullYear() + 1),
        ).toISOString();
    }

    getFormattedActivationDate(activationDate: string): string {
        if (!activationDate) {
            return '--';
        }

        // Formatear la fecha
        const formattedDate = new Date(activationDate).toLocaleDateString(
            getLanguage(),
            {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
            },
        );

        // Reemplazar caracteres
        return formattedDate.replace(/[.,]/g, '');
    }

    cardType(card: any): string {
        let cardTypeName = '';
        if (card.date) {
            cardTypeName = 'gifts';
        } else if (card.deliveryDate) {
            cardTypeName = 'orders';
        } else if (
            card.operation === 'compensation' ||
            card.operation === 'refund' ||
            card.operation === 'cancellation'
        ) {
            cardTypeName = 'compensations';
        }
        return cardTypeName;
    }

    compensationType(id: string): string {
        const keyWords = ['SR', 'MP', 'CG'];
        const firstTwo: string = id.substring(0, 2).toLowerCase();
        for (const type of keyWords) {
            if (firstTwo.includes(type.toLowerCase())) {
                return type;
            }
        }
        return '';
    }

    redirectCompensation(): void {
        window.open(
            this.translate.instant('vg_landing_dashboard_url_payment-methods'),
            '_blank',
        );
    }
    redirectDetails(type: string) {
        const action =
            type == 'gifts' ? 'click_gifts_detail' : 'click_purchases_detail';
        this.gtmActions(action);
    }
    gtmActions(action: string) {
        this.gtmDataLayer.execute<GtmLandingDashboard>({
            event: 'ev_viva_gift_db',
            category: 'viva_gift',
            action: action,
        });
    }
}
